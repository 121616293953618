<template>
  <KTPortlet title="Tạo mới khuyến mại" headSize="md">
    <template v-slot:body>
      <b-form @submit.prevent="onSubmit">
        <b-form-group id="input-group-1" label="Tên" label-for="input-1">
          <b-form-input
            id="input-1"
            type="text"
            required
            v-model="form.name"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-2"
          label="Kiểu khuyến mại"
          label-for="input-6"
        >
          <b-form-select
            id="input-6"
            v-model="form.typePromo"
            :options="listTypePromo"
            value-field="value"
            text-field="text"
            required
          >
          </b-form-select>
        </b-form-group>
        <b-form-group id="input-group-3" label="Giá trị" label-for="input-3">
          <b-form-input
            id="input-3"
            type="number"
            v-model="form.value"
            min="0"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-3" label="Trạng thái" label-for="input-4">
          <b-form-select
            id="input-4"
            v-model="form.status"
            :options="listStatus"
            value-field="value"
            text-field="text"
            required
          ></b-form-select>
        </b-form-group>

        <b-form-group label="Tiệm">
          <v-select
            :options="allOutlets"
            label="name"
            v-model="outLetsSelected"
            multiple
          >
            <template #search="{attributes, events}">
              <input
                class="vs__search"
                :required="!outLetsSelected"
                v-bind="attributes"
                v-on="events"
              />
            </template>
          </v-select>
        </b-form-group>

        <b-form-group id="input-group-3" label="Mô tả" label-for="input-5">
          <b-form-textarea
            id="textarea"
            v-model="form.description"
            required
            placeholder="Mô tả..."
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>

        <b-button type="submit" variant="primary">Hoàn thành</b-button>
      </b-form>
    </template>
  </KTPortlet>
</template>

<style lang="scss">
@import "./Promo.scss";
</style>

<script>
import KTPortlet from "@/components/Portlet.vue";
import { UserRole } from "./../../constans";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import vSelect from "vue-select";
import Loader from "@/common/loader";
import { createSuccess } from "@/helper/index";

export default {
  name: "Create",
  components: {
    KTPortlet,
    vSelect
  },
  data() {
    return {
      form: {
        name: "",
        typePromo: "",
        description: "",
        value: "",
        status: "",
        outletId: ""
      },
      outLetsSelected: [],
      listTypePromo: [
        {
          value: 0,
          text: "Phần trăm"
        },
        {
          value: 1,
          text: "Tiền"
        }
      ],
      listStatus: [
        {
          value: 1,
          text: "Kích hoạt"
        },
        {
          value: 2,
          text: "Chưa kích hoạt"
        }
      ]
    };
  },

  computed: mapGetters(["allOutlets"]),

  created() {
    this.getListUser();
    this.$store.dispatch("resetListOutlets");
    this.getListOutlet();
  },

  methods: {
    onSubmit() {
      this.form.outletId = this.outLetsSelected.map(item => {
        return item._id;
      });
      // this.form.outletId = this.outLetsSelected._id
      this.$store.dispatch("createPromo", this.form).then(() => {
        createSuccess("promo");
      });
    },

    getListUser() {
      Loader.fire();
      this.$store
        .dispatch("getUsers", { role: UserRole.OutletBoss })
        .then(() => {
          this.listManager = this.$store.state.auth.listUser;
        })
        .finally(Loader.close);
    },

    getListOutlet() {
      Loader.fire();
      this.$store.dispatch("setOutlets", { status: 1 }).finally(Loader.close);
    }
  }
};
</script>
